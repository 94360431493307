const SECONDARY = '#00C1C4'

export default {
  palette: {
    primary: {
      main: '#6001D2'
    },
    secondary: {
      main: SECONDARY
    },
    background: {
      default: '#E4E4E4'
    }
  },
  typography: {
    fontSize: 13,
    fontFamily: 'Poppins'

  },
  overrides: {
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: SECONDARY
        }
      }
    },
    MuiInputBase: {
      root: {
        '&$focused': {
          color: SECONDARY
        }
      }
    },
    MuiInput: {
      underline: {
        '&$focused:after': {
          borderBottomColor: SECONDARY
        }
      }
    }
  }
}
