import React from 'react'
import { Toolbar, AppBar, Collapse, CardContent } from '@material-ui/core'
import styled from 'styled-components'

const AppBarStyled = styled(AppBar)`
  top: auto !important;
  bottom: 0;
`

const ToolbarStyled = styled(Toolbar)`
  display: flex;
  flex-direction: column;
  background: #dbdbdb 0% 0% no-repeat padding-box;
  color: #262121;
`

const ToolbarContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  padding-top: 15px;
`

const Overlay = styled.div`
  position: fixed;
  background-color: #E4E4E4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  opacity: 0.5;
`

const UiFooterDetails = ({ resume, details, drawer = false }) => {
  return (
    <>
      {drawer && <Overlay />}
      <AppBarStyled position="fixed">
        <ToolbarStyled>
          <Collapse in={drawer} timeout="auto" unmountOnExit>
            <CardContent>{details}</CardContent>
          </Collapse>
          <ToolbarContent>{resume}</ToolbarContent>
        </ToolbarStyled>
      </AppBarStyled>
    </>
  )
}

export default UiFooterDetails
