import React, { useState, useEffect } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { UiButton, UiVbox, UiFeedbackSubscription } from '../../../ui'
import MyTicketsButtonStateful from '../shared/MyTicketsButtonStateful'
import useStore from 'stores/useStore'
import { copyToClipboard } from '../../../utils'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
  },
}))

type DoneBoletoProps = {
  barcode: string
  email: string
  url: string
  leaderParticipant: any
}

export function DoneBoletoScreen(props: DoneBoletoProps) {
  const {
    barcode = '23791.22928 60003.563230 20000.046902 4 79980000005000',
    email = 'teste@teste.com',
    url = 'https://api.pagar.me/1/boletos/live_cjzvltmen3kjofc3cbk06srqn',
  } = props

  const { setIsSubscriptionComplete } = useStore()

  const classes = useStyles()

  useEffect(() => {
    setIsSubscriptionComplete(true)
  }, [setIsSubscriptionComplete])

  return (
    <UiVbox>
      <Typography variant="h6">Pedido feito com sucesso!</Typography>
      <Typography variant="subtitle1">
        Sua inscrição será confirmada somente <br /> após a confirmação do pagamento do boleto.{' '}
        <br />
        Os detalhes do seu pedido foram <br />
        enviados para o seu email: <br />
        <b style={{ fontWeight: 500 }}>{email}</b>
      </Typography>

      <UiVbox spacing={1}>
        <UiButton
          title={url}
          href={url}
          target="blank"
          variant="outlined"
          color="secondary"
          className={classes.root}>
          Abrir Boleto
        </UiButton>

        <ClipboardButton value={barcode} />
      </UiVbox>

      <MyTicketsButtonStateful />

      <Typography variant="subtitle1">
        Dica útil: verifique também em SPAM, caso não <br />
        esteja na sua caixa de entrada.
      </Typography>
      <UiFeedbackSubscription />
    </UiVbox>
  )
}

function ClipboardButton({ value }: { value: string }) {
  const classes = useStyles()
  const [copied, setCopied] = useState(false)

  function onClick() {
    copyToClipboard(value)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return copied ? (
    <UiButton color="secondary" onClick={onClick}>
      código copiado!
    </UiButton>
  ) : (
    <UiButton
      title={value}
      variant="outlined"
      color="secondary"
      className={classes.root}
      onClick={onClick}>
      Copiar Código de barras
    </UiButton>
  )
}
