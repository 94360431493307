import { API_URL } from '../env'
import { postLog } from '../logflare/postLog'
import { transactionErrorHandler } from './transactionErrorHandler'

export async function validateEnrollment(body) {
  // const http500 = 'http://httpstat.us/500'
  const url = `${API_URL}/api/v2/enroll/validate`

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      await transactionErrorHandler(response)
    }
    const data = await response.json()
    return data
  } catch (e) {
    if (e instanceof SyntaxError) {
      await postLog(body, 'ERRO: SyntaxError', '2561f024-3cd9-4d15-98f8-bbce1d896826')
      throw new Error('Erro na conexão com o serviço. Contate o suporte.')
    }
    if (e instanceof TypeError) {
      await postLog(body, 'ERRO: TypeError', '2561f024-3cd9-4d15-98f8-bbce1d896826')
      throw new Error('Erro na conexão. Verifique sua internet e tente novamente.')
    }
    throw e
  } finally {
    await postLog(body, 'ENVIADO PARA VALIDAÇÃO NO RAILS', 'bf3f48fc-0140-4569-aad2-b66893721510')
  }
}
