import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import AppBarContainer from '../../shared/AppBarContainer'
import useStore from '../../stores/useStore'
import PaymentListContainer from './PaymentListContainer'
import { sessionStorage } from '../../safe-storage'
import GTM from '../../business/gtm/googleTagManager'

export function PaymentPage() {
  const { getParticipants, selectedCategories, sumWithFee, enabledCoupons } = useStore()
  sessionStorage.setItem('userDataParticipantsData', JSON.stringify(getParticipants()))

  const gaItems = selectedCategories.map((el: any) => {
    return {
      ...el,
      item_id: el.id,
      item_name: el.name,
      item_category: el.name,
      price: Number(el.price / 100),
    }
  })

  useEffect(() => {
    GTM.BeginCheckout(gaItems, Number(sumWithFee / 100), enabledCoupons.join(', '))
  }, [gaItems, sumWithFee, enabledCoupons])

  return (
    <>
      <AppBarContainer />
      <Box p={1} mt={1}>
        <PaymentListContainer />
      </Box>
    </>
  )
}
