import { Link } from '@material-ui/core'
import styled from 'styled-components'

const UiLink = styled(Link)`
  && {
    i {
      color: #00c1c4;
      a:link,
      a:visited,
      a:hover,
      a:active {
        color: #00c1c4;
      }
      cursor: pointer;
    }
  }
  &&.MuiTypography-colorPrimary {
    color: #00c1c4;
    a:link,
    a:visited,
    a:hover,
    a:active {
      color: #00c1c4;
    }
    cursor: pointer;
  }
`

export default UiLink
