import { useTimer } from 'react-timer-hook'
import React from 'react'
import useStore from '../../stores/useStore'
import { Digit } from './Digit'

export function Timer({ expiryTimestamp }: any) {
  const { setPixPayTimeExpired } = useStore()

  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => setPixPayTimeExpired(true),
  })

  return (
    <>
      <Digit value={minutes} />
      <span style={{ color: '#00C1C4' }}>:</span>
      <Digit value={seconds} />
    </>
  )
}
