import React from 'react'
import { noty } from '../../components/SnackbarQueue'
import { UiButton } from '../../ui'
import { view } from 'react-easy-state'
import { useLocation } from 'wouter'
import useStore from '../../stores/useStore'
import UiBusyWrapper from '../../ui/UiBusyWrapper'
import useBusy from '../../hooks/useBusy'
import { GA4, Pixel } from 'business'

function FreeButtonContainer() {
  const {
    updateParticipantFieldsOptionals,
    hasParticipantsInvalid,
    enrollByFree,
    setUrl,
    event,
    enableErrors,
  } = useStore()
  const [, setLocation] = useLocation()
  const [busy, busyPay, setLoading] = useBusy(pay, 'PaymentFreePage')

  async function pay() {
    event.integrations.pixel_lead && Pixel.Lead(event.integrations.pixel_id)
    GA4.Participants()
    updateParticipantFieldsOptionals()
    enableErrors()
    if (hasParticipantsInvalid) {
      noty.error('Há campos não preenchidos ou preenchidos incorretamente.')
      setLoading(false)
    } else {
      try {
        setLoading(true)
        const url = await enrollByFree()
        setUrl(url)
        setLoading(false)
        setLocation('/done/ProcessingPage')
      } catch (error) {
        setLoading(false)
        noty.error(error.message || error.json?.message?.substr(0, 100))
      }
    }
  }

  return (
    <UiBusyWrapper busy={busy} uid="PaymentFreePage">
      <UiButton onClick={busyPay}>finalizar</UiButton>
    </UiBusyWrapper>
  )
}

export default view(FreeButtonContainer)
