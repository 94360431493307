import React from 'react'
import { Box } from '@material-ui/core'
import { UiCard } from '../../ui'

type LayoutProps = {
  children: React.ReactNode
  showTag?: boolean
}

export function CategoryItemLayout({ children, showTag = false }: LayoutProps) {
  return (
    <Box mb={1}>
      <UiCard showTag={showTag} colorTag={'#00C1C4'}>
        {children}
      </UiCard>
    </Box>
  )
}
