import React from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Typography } from '@material-ui/core'

export function PasswordRules(props) {
  const { fieldPassword } = props

  const isMinPassword = fieldPassword.trim().length >= 8
  const isUppercasePassword = /[A-Z]/.test(fieldPassword)
  const isLowercasePassword = /[a-z]/.test(fieldPassword)
  const iskNumericPassword = /\d/.test(fieldPassword)
  const isSymbolPassword = /[!@#$%^&*(),.?":{}|<>]/.test(fieldPassword)

  return (
    <>
      <Typography variant="body2">Sua senha deve possuir:</Typography>
      <div style={{ display: 'flex', gap: 6, margin: '3px 0px' }}>
        - 8 caracteres
        {isMinPassword && <CheckCircleIcon color="secondary" style={{ fontSize: 16 }} />}
      </div>

      <div style={{ display: 'flex', gap: 6, marginBottom: 3 }}>
        - uma letra maiúscula
        {isUppercasePassword && <CheckCircleIcon color="secondary" style={{ fontSize: 16 }} />}
      </div>

      <div style={{ display: 'flex', gap: 6, marginBottom: 3 }}>
        - uma letra minúscula
        {isLowercasePassword && <CheckCircleIcon color="secondary" style={{ fontSize: 16 }} />}
      </div>

      <div style={{ display: 'flex', gap: 6, marginBottom: 3 }}>
        - um carácter númerico
        {iskNumericPassword && <CheckCircleIcon color="secondary" style={{ fontSize: 16 }} />}
      </div>

      <div style={{ display: 'flex', gap: 6 }}>
        - um carácter de símbolo
        {isSymbolPassword && <CheckCircleIcon color="secondary" style={{ fontSize: 16 }} />}
      </div>
    </>
  )
}
