import emailRegex from '../../utils/emailRegex'
import isDocumentValid from '../../utils/isDocumentValid'
import useStore from '../../stores/useStore'

export default function (participant, eventDocuments) {
  const { isDocRequired } = useStore()
  let hasErrors = false

  participant.participantErrors.name = ''
  participant.participantErrors.email = ''
  participant.participantErrors.document_type = ''
  participant.participantErrors.document_value = ''

  let nameParts = participant.name.trim().split(/\s+/)

  if (!(nameParts.length >= 2)) {
    participant.participantErrors.name = 'Não pode conter somente o primeiro nome'
    hasErrors = true
  }

  if (!(participant.name.length > 3)) {
    participant.participantErrors.name = 'Nome deve ter mais de 3 letras'
    hasErrors = true
  }

  if (!emailRegex.test(participant.email)) {
    participant.participantErrors.email = 'E-mail inválido'
    hasErrors = true
  }

  if (isDocRequired) {
    if (!eventDocuments.includes(participant.document_type)) {
      participant.participantErrors.document_type = 'Selecione um tipo de documento'
      hasErrors = true
    }

    if (!participant.document_type) {
      participant.participantErrors.document_type = 'Selecione um tipo de documento'
      hasErrors = true
    }

    if (!isDocumentValid(participant.document_value, participant.document_type)) {
      participant.participantErrors.document_value = 'Documento inválido'
      hasErrors = true
    }
  }
  return hasErrors
}
