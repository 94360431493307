export async function postLog(payload, message, sourceId) {
  const headers = {
    'Content-Type': 'application/json',
  }

  //Envia somente o metadata
  if (payload.hasOwnProperty('_options')) {
    const body = JSON.parse(payload._options.body)
    payload._options.body = body.metadata
  }

  const logflareEventBody = {
    event_message: message,
    metadata: payload,
  }

  const init = {
    method: 'POST',
    headers,
    body: JSON.stringify(logflareEventBody),
  }
  try {
    await fetch(`https://api.logflare.app/logs/json?api_key=y-ap7DUq8pyK&source=${sourceId}`, init)
  } catch (e) {
    console.error(e)
  }
}

// curl -X "POST" "https://api.logflare.app/api/logs?source=551ae926-9a44-48f3-872a-33c922ffc8de" \
// -H 'Content-Type: application/json' \
// -H 'X-API-KEY: y-ap7DUq8pyK' \
// -d $'{
//   "event_message": "This is another log message.",
//   "metadata": {
//     "ip_address": "100.100.100.100",
//     "request_method": "POST",
//     "custom_user_data": {
//       "vip": true,
//       "id": 38,
//       "login_count": 154,
//       "company": "Apple",
//       "address": {
//         "zip": "11111",
//         "st": "NY",
//         "street": "123 W Main St",
//         "city": "New York"
//       }
//     },
//     "datacenter": "aws",
//     "request_headers": {
//       "connection": "close",
//       "user_agent": "chrome"
//     }
//   }
// }'
