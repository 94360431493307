import { MenuItem, ListItemText } from '@material-ui/core'
import React from 'react'
import UiTextField from './UiTextField'

const SelectNative = ({ options = [], allowEmpty = false, multiple = false, ...props }) => (
  <UiTextField select SelectProps={{ native: true, multiple }} {...props}>
    {allowEmpty && <option value={null} />}

    {options.map(([key, label, disabled]) => (
      <option key={key} value={key} disabled={disabled} style={{background: "#FFF"}}>
        {label}
      </option>
    ))}
  </UiTextField>
)

const SelectMenu = ({ options = [], allowEmpty = false, ...props }) => (
  <UiTextField select {...props}>
    {allowEmpty && <MenuItem value={false} />}

    {options.map(([key, label, disabled]) => (
      <MenuItem key={key} value={key} disabled={disabled}>
        <ListItemText primary={label} />
      </MenuItem>
    ))}
  </UiTextField>
)

const UiSelect = ({ native = false, multiple = false, ...props }) =>
  native ? <SelectNative multiple={multiple} {...props} /> : <SelectMenu {...props} />

export default UiSelect
