import R from '../request'
import wretch from 'wretch'
import { API_URL, API_PARTICIPANTS_URL } from '../env'
import { postLog } from 'logflare/postLog'

const events = R.url('/events')

const API = {
  getEvent(event_id) {
    return events.url(`/${event_id}`).get()
  },

  // getParticipantData(id) {
  //   return wretch(`https://5f74abca1cf3c900161cd617.mockapi.io/api/v2/participants/${id}`)
  //     .get()
  //     .json()
  // },

  async getParticipantData(eventId, userId, participantId) {
    if (
      API_PARTICIPANTS_URL === 'staging' ||
      API_PARTICIPANTS_URL === 'https://e-inscricao.appz.workers.dev'
    ) {
      return wretch(
        `https://e-inscricao.appz.workers.dev/participants/event/${eventId}/user/${userId}/participant/${participantId}`
      )
        .get()
        .json()
    }

    let url = `${API_PARTICIPANTS_URL}/events/${eventId}/users/${userId}/participants/${participantId}`
    await postLog(
      { eventId: eventId, userId: userId, participantId: participantId },
      'CONSULTOU PARTICIPANTE NO MS',
      '551ae926-9a44-48f3-872a-33c922ffc8de'
    )

    return wretch(url).get().json()
  },

  async getParticipantList(eventId, userId) {
    if (
      API_PARTICIPANTS_URL === 'staging' ||
      API_PARTICIPANTS_URL === 'https://e-inscricao.appz.workers.dev'
    ) {
      return wretch(
        `https://e-inscricao.appz.workers.dev/participants/event/${eventId}/user/${userId}`
      )
        .get()
        .json()
    }
    let url = `${API_PARTICIPANTS_URL}/events/${eventId}/users/${userId}/participants`
    await postLog(
      { eventId: eventId, userId: userId },
      'CONSULTOU LISTA NO MS',
      '551ae926-9a44-48f3-872a-33c922ffc8de'
    )

    return wretch(url).get().json()
  },

  enrollFree(payload) {
    return R.url('/enroll/free').post({ payload })
  },

  enrollBoleto(payload) {
    return R.url('/enroll/boleto').post({ payload })
  },

  enrollCard(payload) {
    return R.url('/enroll/card').post({ payload })
  },

  enrollDebit(payload) {
    return R.url('/enroll/debit').post({ payload })
  },

  async signIn(email, password) {
    const url = `${API_URL}/api/v2/signin`
    // const url_error = `http://httpstat.us/500`
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ email, password }),
      })

      if (!response.ok) {
        if (response.status >= 400 && response.status <= 499) {
          const error = await response.json()
          throw new Error(Object.values(error.errors.user_authentication).join(' '))
        }
      }
      const data = await response.json()
      return data
    } catch (e) {
      if (e instanceof SyntaxError) {
        throw new Error('Erro na conexão com o serviço. Contate o suporte.')
      }
      if (e instanceof TypeError) {
        throw new Error('Erro na conexão. Verifique sua internet e tente novamente.')
      }
      throw e
    }
  },

  async signUp(user) {
    const url = `${API_URL}/api/v2/signup`
    // const url_error = `http://httpstat.us/500`
    user.email = user.email.replace(/\s+/g, '')
    const init = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'default',
      },
      body: JSON.stringify({ user }),
    }
    try {
      const response = await fetch(url, init)
      if (response.status >= 400 && response.status <= 499) {
        throw await response.json()
      } else if (response.status >= 500 && response.status <= 599) {
        const error = Error('Serviço indisponível. Tente mais tarde!')
        throw error
      } else {
        return response.json()
      }
    } catch (e) {
      throw e
    }
  },

  // request user:
  // accept: true
  // document_type: "cpf"
  // document_value: "123.456.789-09"
  // email: "contato@qi64.com"
  // name: "Marcos Neves"
  // password: "123456"
  // password_confirmation: "123456"

  // response
  // "{
  //   "data": {
  //     "id": "12",
  //     "type": "user",
  //     "attributes": {
  //       "name": "Marcos Neves",
  //       "email": "contato@qi64.com",
  //       "document_type": "cpf",
  //       "document_value": "12345678909",
  //       "participant_id": 16
  //     }
  //   },
  //   "meta": {
  //     "token": "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMn0.L8yroOYctJqcMo4hMVVljc9SlfZzO1Q9657EYbmEI6c"
  //   }
  // }"

  // FIXME implementar na interface
  getCep(zipcode, countryAcronym = 'BR') {
    return wretch('https://hi93xtn4oi.execute-api.sa-east-1.amazonaws.com/production/zipcode')
      .query({ zipcode, countryAcronym })
      .get()
      .json()
      .then(j => j.body)
  },

  resetPassword(email) {
    return wretch()
      .url(API_URL + '/users/password')
      .post({
        user: { email },
      })
      .res()
  },
}

export default API
