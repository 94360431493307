import { Box, Typography } from '@material-ui/core'
import { useLocation } from 'wouter'
import { noty } from '../../components/SnackbarQueue'
import React, { useEffect, useState } from 'react'
import { view } from 'react-easy-state'
import AppBarContainer from '../../shared/AppBarContainer'
import FooterBarContainer from '../../shared/FooterBarContainer'
import { UiButton } from '../../ui'
import UiBusyWrapper from '../../ui/UiBusyWrapper'
import useStore from '../../stores/useStore'
import PixCardDetails from './PixCardDetails'
import { Pixel } from '../../business'

function PaymentPixPage({ params: { id } }: any) {
  const { setPixObject, setPaymentMethodId, enrollByPix, setUrl, event } = useStore()

  const [, setLocation] = useLocation()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setPaymentMethodId(id)
  }, [setPaymentMethodId, id])

  async function pay() {
    event.integrations?.pixel_add_payment_info && Pixel.AddPaymentInfo(event.integrations.pixel_id)
    try {
      setLoading(true)
      const pix = await enrollByPix()
      setPixObject(pix)
      setUrl(pix.url_status)
      setLoading(false)
      setLocation('/done/pix')
    } catch (error) {
      setLoading(false)
      noty.error((error as Error).message)
    }
  }

  return (
    <>
      <AppBarContainer />
      <Box m={1}>
        <Box m={2}>
          <Typography variant="h6" align="center">
            Instruções de pagamento via Pix
          </Typography>
        </Box>
        <PixCardDetails />
      </Box>
      <FooterBarContainer>
        <UiBusyWrapper busy={loading} uid="PaymentPixPage">
          <UiButton disabled={loading} onClick={pay}>
            GERAR CÓDIGO PIX
          </UiButton>
        </UiBusyWrapper>
      </FooterBarContainer>
    </>
  )
}

export default view(PaymentPixPage)
