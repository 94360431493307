/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { useLocation } from 'wouter'
import { Formik } from 'formik'
import * as yup from 'yup'
import { sessionStorage } from '../../safe-storage'

import { UiButton } from '../../ui'
import AppBarContainer from '../../shared/AppBarContainer'
import FooterBarContainer from '../../shared/FooterBarContainer'

import useStore from '../../stores/useStore'
import { IParticipant, ISelectedOptionals, IOptionalItem, IOptions } from './OptionalPage'
import OptionalPageItem from './OptionalPageItem'
import FreeButtonContainer from '../../pages/ParticipantsPage/FreeButtonContainer'

const OptionalPageList = () => {
  const {
    getParticipantsList,
    setParticipantOptionals: storeParticipantOptionals,
    optionals: storeOptionals,
    setOptionalsStore,
    updateParticipantFieldsOptionals,
    sum,
  } = useStore()
  const [, setLocation] = useLocation()
  //Lista de participantes no evento
  const participants: Array<IParticipant> = getParticipantsList
  //Lista de opcionais disponíveis no evento
  const [optionals, setOptionals] = useState<Array<IOptionalItem>>(storeOptionals)
  //Lista de participantes com os opcionais selecionados
  const [optionalsParticipantList, setOptionalsParticipantList] = useState<
    Array<ISelectedOptionals>
  >([
    {
      participantId: '',
      optionals: [],
    },
  ])
  const [yupFields, setYupFields] = useState({})
  const [initialValues, setInitialValues] = useState({})
  const [fieldNames, setFieldNames] = useState({})

  useEffect(() => {
    const json = sessionStorage.getItem('participantsOptionals')
    if (json) {
      const participantsOptionals = JSON.parse(json)
      setOptionalsParticipantList(participantsOptionals)
    }

    let yupFields = {}
    let initialValues = {}
    let generateFieldsNames = {}

    participants.forEach((participant: IParticipant, index: number) => {
      const participantId = participant.id.replace(/-/g, '')
      const participantFieldNames: Array<string> = optionals?.map(
        opt => `optional${opt.id}${participantId}`
      )
      const fieldsRequired = optionals?.map(opt => {
        return {
          [`optional${opt.id}${participantId}`]: opt.required
            ? yup
                .number()
                .moreThan(0, 'Opcional é obrigatório!')
                .required('Opcional é obrigatório!')
            : yup.number(),
        }
      })

      yupFields = { ...yupFields, ...fieldsRequired?.reduce((o, key) => ({ ...o, ...key }), {}) }
      initialValues = {
        ...initialValues,
        ...participantFieldNames?.reduce((o, key) => ({ ...o, [key]: 0 }), {}),
      }
      generateFieldsNames = {
        ...generateFieldsNames,
        [participantId]: participantFieldNames,
      }
    })
    setInitialValues(initialValues)
    setYupFields(yupFields)
    setFieldNames(generateFieldsNames)
  }, [])

  useEffect(() => {
    setOptionalsStore(optionals)
  }, [optionals])

  const getParticipantOptionals = (participantId: string) => {
    const optionalsParticipant = optionalsParticipantList.find(
      opt => opt.participantId === participantId
    )
    return optionalsParticipant?.optionals
  }

  const updateParticipantOptionsSoldout = (
    participantId: string,
    optionalId: number,
    optionId: number
  ) => {
    if (optionId !== 0) {
      const optionalsParticipant = getParticipantOptionals(participantId)
      const result = optionalsParticipant?.map(({ optionalId: optionalIdPart, optionId }) => {
        if (optionalIdPart === optionalId) {
          const optionalPart = optionals.filter(opt => opt.id === optionalId)[0]
          const { options } = optionalPart
          const selectedOption: any = options?.find(opt => opt.id === optionId)
          const currentInUse =
            selectedOption.current_in_use !== 0
              ? selectedOption.current_in_use - 1
              : selectedOption.current_in_use
          const limit = selectedOption.limit || 0
          const soldout = !(limit - currentInUse)
          const updatedOption: any = {
            ...selectedOption,
            current_in_use: currentInUse,
            soldout,
          }
          return updatedOption
        }
      })
      return result?.filter(optional => optional)[0]
    }
  }

  const addOrRemove = (
    operation: string,
    participantId: string,
    optionalId: number,
    optionId: number,
    selectedOption: IOptions,
    options: Array<IOptions> | undefined,
    newOptional: IOptionalItem
  ) => {
    const limit = selectedOption.limit || 0
    if (limit !== 0) {
      const currentInUse =
        operation === 'add' ? selectedOption.current_in_use + 1 : selectedOption.current_in_use - 1
      const soldout = !(limit - currentInUse)
      const updatedOption = {
        ...selectedOption,
        current_in_use: currentInUse,
        soldout,
      }
      const updateLastOption = updateParticipantOptionsSoldout(participantId, optionalId, optionId)
      const newOptions = options
        ?.filter(option => option.id !== selectedOption.id)
        .filter(option => option.id !== updateLastOption?.id)
      if (newOptions) {
        const updatedOptions = updateLastOption
          ? [...newOptions, updatedOption, updateLastOption]
          : [...newOptions, updatedOption]

        const newOptionals = optionals.map(opt => {
          if (opt.id === optionalId) {
            return {
              ...opt,
              options: updatedOptions,
            }
          }
          return opt
        })
        setOptionals([...newOptionals])
      }
    }
  }

  const updateOptionsSoldout = (participantId: string, optionId: number, optionalId: number) => {
    const optional = optionals.find(opt => opt.id === optionalId)
    if (optional) {
      const { options, ...newOptional } = optional
      const selectedOption = options?.filter(option => option.id === optionId)[0]
      if (selectedOption === undefined) return

      if (selectedOption) {
        addOrRemove(
          'add',
          participantId,
          optionalId,
          optionId,
          selectedOption,
          options,
          newOptional
        )
      } else {
        const optionalsParticipant = getParticipantOptionals(participantId)
        const participantOptional = optionalsParticipant?.find(
          opt => opt?.optionalId === optionalId
        )
        const optional = optionals.filter(opt => opt.id === participantOptional?.optionalId)[0]
        const { options, ...newOptional } = optional
        const selectedOption = options?.find(option => option.id === participantOptional?.optionId)
        if (selectedOption) {
          addOrRemove(
            'remove',
            participantId,
            optionalId,
            optionId,
            selectedOption,
            options,
            newOptional
          )
        }
      }
    }
  }

  const handleChangeOptionalItem = (
    participantOptionals: ISelectedOptionals,
    participantId: string,
    optionId: number,
    optionalId: number
  ) => {
    const optionalsParticipantListResult = optionalsParticipantList.filter(
      value =>
        value.participantId !== '' && value.participantId !== participantOptionals.participantId
    )
    const resultOptionalsParticipant = [
      ...optionalsParticipantListResult,
      { ...participantOptionals },
    ]

    const participantsOptionalsFiltered = [
      ...participants.map(
        part =>
          resultOptionalsParticipant.filter(
            (value: ISelectedOptionals) =>
              value.participantId !== '' && value.participantId === part.id
          )[0]
      ),
    ]

    const notnullList = participantsOptionalsFiltered.filter(value => value)

    updateOptionsSoldout(participantId, optionId, optionalId)
    storeParticipantOptionals(notnullList)
    setOptionalsParticipantList(notnullList)
    sessionStorage.setItem('participantsOptionals', JSON.stringify(notnullList))
  }
  return (
    <>
      <AppBarContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(yupFields)}
        onSubmit={() => {
          setLocation('payment')
          updateParticipantFieldsOptionals()
        }}>
        {({ handleSubmit }) => {
          return (
            <Box m={1}>
              {participants.map((participant: IParticipant, index: number) => (
                <OptionalPageItem
                  index={index}
                  key={`OptionalPageItem-${index}`}
                  fieldNames={fieldNames}
                  participant={participant}
                  optionals={optionals}
                  optionalsParticipantList={optionalsParticipantList}
                  onChange={(
                    participantOptionals: ISelectedOptionals,
                    participantId: string,
                    optionId: number,
                    optionalId: number
                  ) =>
                    handleChangeOptionalItem(
                      participantOptionals,
                      participantId,
                      optionId,
                      optionalId
                    )
                  }
                />
              ))}
              <FooterBarContainer>
                {sum ? (
                  <UiButton onClick={handleSubmit}>continuar</UiButton>
                ) : (
                  <FreeButtonContainer />
                )}
              </FooterBarContainer>
            </Box>
          )
        }}
      </Formik>
    </>
  )
}

export default OptionalPageList
