import { Box } from '@material-ui/core'
import Busy from '../../../components/Busy'
import React, { useState } from 'react'
import { UiButton, UiCheckbox } from '../../../ui'
import { Formik } from 'formik'
import * as yup from 'yup'
import FieldFormik from '../../../inputs/formik/FieldFormik'
import MailCheckInputFormik from '../../../inputs/formik/MailCheckInputFormik'
import useStore from '../../../stores/useStore'
import Tracker from '../../../plugins/tracker'
import { useLocation } from 'wouter'
import { noty } from '../../../components/SnackbarQueue'

export function SigninForm() {
  const { signIn } = useStore()
  const [, setLocation] = useLocation()
  const [showPassword, setShowPassword] = useState(false)

  const validationSchema = () =>
    yup.object().shape({
      email: yup.string().trim().email('Email inválido!').required('Email é obrigatório!'),
      password: yup.string().min(6, 'Mínimo 6 caracteres').required('Senha é obrigatório!'),
    })

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          setSubmitting(true)
          const result = await signIn(values.email, values.password)
          Tracker.signIn(result)
          setLocation('/participants')
          setSubmitting(false)
        } catch (error) {
          const authError = error?.errors ? Object.values(error.errors) : null
          setSubmitting(false)
          error?.errors && setErrors(error.errors)
          console.error('errors', authError || error)
          noty.error(error?.errors ? 'Usuário ou Senha Inválida!' : error.message)
        }
      }}>
      {({
        handleSubmit,
        isSubmitting,
        handleChange,
        handleBlur,
        values,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <>
            <MailCheckInputFormik name="email" label="Email" required />
            <FieldFormik
              name="password"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              required
            />
            <UiCheckbox label="Mostrar senha" onChange={() => setShowPassword(!showPassword)} />
            <Box mt={3}>
              <Busy busy={isSubmitting} block>
                <UiButton color="default" type="submit" onClick={handleSubmit}>
                  Entrar
                </UiButton>
              </Busy>
            </Box>
          </>
        )
      }}
    </Formik>
  )
}
