import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import useStore from '../../stores/useStore'
import { view } from 'react-easy-state'
import { useLocation } from 'wouter'
import AppBarContainer from '../../shared/AppBarContainer'
import { getStatusTransaction } from 'stores/services'
import { InfoIcon } from 'icons/material'
import ReactLoading from 'react-loading'

function ProcessingPage() {
  const [, setLocation] = useLocation()
  const [date, setDate] = useState(Date.now())
  // const [times, setTimes] = useState(0)
  const { setResultMessage, getUrl, pagarmeBoleto } = useStore()

  // const payment_type = getPaymentMethod()?.payment_type && getPaymentMethod().payment_type

  // const url = getUrl()

  const checkTransactionStatus = async () => {
    try {
      const response = await getStatusTransaction(getUrl())
      if (response === null) {
        console.log(date)
      } else if (response.status === false) {
        setResultMessage(response.message)
        setLocation('/done/ErrorPage')
      } else {
        if (pagarmeBoleto?.transaction?.boleto_barcode) setLocation('/done/boleto')
        else setLocation('/done/paid')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDate(Date.now())
      checkTransactionStatus()
    }, 1000)

    return () => clearTimeout(timer)
  })

  // useEffect(() => {
  //   const resend = async () => {
  //     try {
  //       const id = url.split('/')[6].split('.')[0]
  //       await resendPostback(id)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   const isResendable = payment_type === 'cartao'
  //   if (times < 3) {
  //     const timer = setTimeout(() => {
  //       setTimes(prev => prev + 1)
  //       isResendable && resend()
  //     }, 10000)
  //     return () => clearInterval(timer)
  //   }
  // }, [times, url, payment_type])

  return (
    <>
      <AppBarContainer />
      <Box textAlign="center" mt={4} fontSize="54px" color="#00C1C4">
        <InfoIcon fontSize="inherit" />
      </Box>
      <Box m={1}>
        <Box m={2}>
          <Typography variant="h6" align="center">
            Aguarde enquanto processamos sua compra.
          </Typography>
        </Box>
        <Box m={4} display="flex" justifyContent="center">
          <ReactLoading type="bubbles" color="#000000" />
        </Box>
      </Box>
    </>
  )
}

export default view(ProcessingPage)
