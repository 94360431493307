import React from 'react'

type DigitProps = {
  value: number
}

export function Digit({ value }: DigitProps) {
  const leftDigit = value >= 10 ? value.toString()[0] : '0'
  const rightDigit = value >= 10 ? value.toString()[1] : value.toString()
  return (
    <>
      <span style={{ color: '#00C1C4' }}>{leftDigit}</span>
      <span style={{ color: '#00C1C4' }}>{rightDigit}</span>
    </>
  )
}
