import isBirthdateValid from 'utils/isBirthdateValid'

export default function (participant, eventFields) {
  const userFields = participant.fields
  const multiChoiceFields = eventFields.filter(
    el => el.kind === 'multi_choice' && el.options.length !== 0
  )

  const eventFieldsWithoutMultiChoice = eventFields.filter(el => el.kind !== 'multi_choice')
  let hasInvalidField = false
  eventFieldsWithoutMultiChoice
    .filter(f => f.required || f.kind === 'term')
    .forEach(f => {
      if (!userFields[f.id]) {
        hasInvalidField = true
        participant.fieldsErrors[f.id] = 'Campo obrigatório'
      } else if (f.kind === 'birthdate') {
        const isValidDate = isBirthdateValid(userFields[f.id])

        if (!isValidDate) {
          participant.fieldsErrors[f.id] = 'Data de nascimento inválida'
          hasInvalidField = true
        } else {
          participant.fieldsErrors[f.id] = ''
        }
      } else {
        participant.fieldsErrors[f.id] = ''
      }
    })

  const multiChoiceUserFields = multiChoiceFields.filter(el => el.required === true)

  let areEmpty = false
  multiChoiceUserFields.forEach(el => {
    const obj = Object.assign({}, userFields[el.id])
    if (obj === undefined || Object.keys(obj).length === 0) {
      areEmpty = true
      participant.fieldsErrors[el.id] = 'Campo obrigatório'
    } else {
      participant.fieldsErrors[el.id] = ''
    }
  })

  return !(hasInvalidField === false && areEmpty === false)
}
