import { Box } from '@material-ui/core'
import { parse } from 'query-string'
import React, { useEffect } from 'react'
import { view } from 'react-easy-state'
import AppBarContainer from '../../shared/AppBarContainer'
import FooterBarContainer from '../../shared/FooterBarContainer'
import useStore from '../../stores/useStore'
import { UiButton, UiText, UiLink } from '../../ui'
import redirect from '../../utils/redirect'
import { useLocation } from 'wouter'
import CategoryListContainer from './CategoryListContainer'
import CouponContainer from './CouponContainer'
import { GroupDiscountContainer } from './GroupDiscountContainer'
import { Pixel, GA4 } from 'business'
import { GTM } from '../../business/gtm/googleTagManager'

function TicketsPage() {
  const {
    hasCoupon,
    cartDisabled,
    setPixPayTimeExpired,
    setPixPaymentCompleted,
    setResultMessage,
    event,
    categoryList,
  } = useStore()
  const [, setLocation] = useLocation()

  const gaItems = categoryList.map((el: any) => {
    return {
      item_id: el.id,
      item_name: el.name,
      item_category: el.name,
      price: Number(el.price / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      ...el,
    }
  })

  useEffect(() => {
    if (event) {
      setTimeout(() => {
        GTM.ViewItemList(gaItems)
        GA4.PageView()
      }, 300)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const sendPixel = async () => {
      Pixel.InitiateCheckout(event.integrations.pixel_id)
    }

    if (event.integrations?.pixel_initiate_checkout) {
      sendPixel()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.integrations?.pixel_id, event.integrations?.pixel_initiate_checkout, event])

  setPixPayTimeExpired(false)
  setPixPaymentCompleted(false)
  setResultMessage('')
  const params = parse(document.location.search)
  const shouldDisplayHeader = !params.show_header || params.show_header === 'true'
  // const seo = useContext(seoContext)
  // seo.pageview(location)

  function continuar() {
    GA4.Subscription()
    setLocation(redirect('participants'))
  }

  const SubscriptionsUnavailable = (
    <Box textAlign="center">
      <UiText>Inscrições indisponíveis</UiText>
      <UiText>
        Saiba mais acessando{' '}
        <UiLink
          href={`https://www.e-inscricao.com/${event.organization?.slug}/${event.id}`}
          target="_blank">
          a página do evento.
        </UiLink>
      </UiText>
    </Box>
  )

  return (
    <>
      {shouldDisplayHeader && <AppBarContainer />}
      <GroupDiscountContainer />
      <Box p={1} mt={1}>
        {hasCoupon && <CouponContainer />}
        {event.free === false && event.payment_methods.length === 0 ? (
          SubscriptionsUnavailable
        ) : (
          <CategoryListContainer />
        )}
      </Box>
      <FooterBarContainer>
        <UiButton disabled={cartDisabled} onClick={continuar}>
          continuar
        </UiButton>
      </FooterBarContainer>
    </>
  )
}

export default view(TicketsPage)
