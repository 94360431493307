import React from 'react'
import { UiSelect } from '../../ui'
import useDocumentTypeContext from './useDocumentTypeContext'

export default function DocumentTypeInput({ types, ...props }) {
  const documentTypes = useDocumentTypeContext()
  types = types || documentTypes

  return (
    <UiSelect
      native
      allowEmpty
      options={options(types)}
      label={'Documento'}
      required
      margin="dense"
      {...props}
    />
  )
}

const DOCUMENT_TYPES = {
  cpf: 'CPF',
  rg: 'RG',
  cnpj: 'CNPJ',
  cnh: 'CNH',
  passport: 'Passaporte',
  birth_certificate: 'Certidão de nascimento',
}

const KEYS = Object.keys(DOCUMENT_TYPES)

function options(types = []) {
  types = types.filter(doc => KEYS.includes(doc))
  return types.map(type => [type, DOCUMENT_TYPES[type]])
}
