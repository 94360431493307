import React from 'react'
import { UiButton } from '../../ui'
import { useLocation } from 'wouter'
import useStore from '../../stores/useStore'
import { view } from 'react-easy-state'
import { noty } from '../../components/SnackbarQueue'
import { Pixel, GA4 } from '../../business'

function NextButtonContainer() {
  const { hasParticipantsInvalid, hasOptional, event, enableErrors } = useStore()
  const [, setLocation] = useLocation()

  function nextPage() {
    enableErrors()
    if (hasParticipantsInvalid) {
      noty.error('Há campos não preenchidos ou preenchidos incorretamente.')
    } else {
      event.integrations.pixel_lead && Pixel.Lead(event.integrations.pixel_id)
      GA4.Participants()
      setLocation(hasOptional ? '/optionals' : '/payment')
    }
  }

  return <UiButton onClick={() => nextPage()}>continuar</UiButton>
}

export default view(NextButtonContainer)
