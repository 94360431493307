// https://mobile.ant.design/components/stepper/
import React from 'react'
import View from './view'

import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { LOG, VERSION, LOG_ID } from '../../env'
import { Pixel } from '../../business'
import useStore from '../../stores/useStore'
import GTM from 'business/gtm/googleTagManager'

export default function StepInput({
  min = 0,
  max = Number.POSITIVE_INFINITY,
  value = 0,
  onChange,
  minTickets,
  ...props
}) {
  const { event, isAddedToCartOnce, setIsAddedToCartOnce } = useStore()
  onChange = onChange || console.warn
  value = parseInt(value) || 0
  value = Math.min(Math.max(value, min), max)

  const logRocket = () =>
    LogRocket.init(LOG_ID, { release: VERSION }) && setupLogRocketReact(LogRocket)

  props.downProps = {
    title: min,
    disabled: value <= min,
    onClick() {
      const gaItem = {
        item_name: props.name,
        price: Number(props.price / 100),
        quantity: value - 1,
      }

      const gaValue = Number(props.price / 100) * gaItem.quantity
      GTM.RemoveFromCart(gaItem, gaValue)
      if (minTickets === value) {
        onChange(value - minTickets)
      } else {
        onChange(value - 1)
      }
      if (LOG) logRocket()
    },
  }

  function isUpDisabled() {
    if (minTickets > max) return true
    return value >= max
  }

  props.upProps = {
    title: max,
    disabled: isUpDisabled(),
    onClick() {
      event.integrations.pixel_add_to_cart &&
        !isAddedToCartOnce &&
        Pixel.AddToCart(event.integrations.pixel_id)
      const gaItem = {
        item_id: props.id,
        item_name: props.name,
        price: Number(props.price / 100),
        quantity: value + 1,
      }
      const gaValue = Number(props.price / 100) * gaItem.quantity
      GTM.AddToCart(gaItem, gaValue)
      if (value === 0) {
        if (minTickets) {
          onChange((value = minTickets))
        } else {
          onChange(value + 1)
        }
      } else {
        onChange(value + 1)
      }
      setIsAddedToCartOnce()
      if (LOG) logRocket()
    },
  }

  function onChangeValue(e) {
    onChange(parseInt(e.target.value))
  }

  return <View {...props} onChange={onChangeValue} value={value} />
}
