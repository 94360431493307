import React from 'react'
import { TextField } from '@material-ui/core'
import styled from 'styled-components'

const TextFieldStyled = styled(TextField)`
  && {
    [class^="MuiInputBase"] {
      font-size: 16px; !important
    }
  }
`

export default function UiTextField({ value, defaultValue, onChange, error = false, ...props }) {
  // cant have defaultValue and value at same time
  // defaultValue is used by react-text-mask
  value = defaultValue !== undefined ? undefined : value || '' // force to be controlled
  return (
    <TextFieldStyled
      type="text"
      fullWidth
      margin="dense"
      value={value}
      defaultValue={defaultValue}
      error={!!error}
      helperText={error}
      autoComplete="no"
      onChange={e => onChange && onChange(e.target.value)}
      {...props}
      inputProps={{
        autoComplete: 'new-password',
      }}
    />
  )
}
