export const GA4 = {
  Initialize(ga4Id) {
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${ga4Id}`
    document.head.appendChild(script)

    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', ga4Id)
  },

  pushToDataLayer(eventObject) {
    window.dataLayer.push(eventObject)
  },

  ViewItemList(items) {
    this.pushToDataLayer({
      event: 'view_item_list',
      ecommerce: {
        item_list_name: 'Tickets',
        item_list_id: 'tickets',
        items: items,
      },
    })
  },

  AddToCart(item, value) {
    this.pushToDataLayer({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'BRL',
        value: value,
        items: [{ ...item }],
      },
    })
  },

  RemoveFromCart(item, value) {
    this.pushToDataLayer({
      event: 'remove_from_cart',
      ecommerce: {
        currency: 'BRL',
        value: value,
        items: [{ ...item }],
      },
    })
  },

  ViewCart(items, value) {
    this.pushToDataLayer({
      event: 'view_cart',
      ecommerce: {
        currency: 'BRL',
        items: items,
        value: value,
      },
    })
  },

  BeginCheckout(items, value, coupon) {
    this.pushToDataLayer({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'BRL',
        value: value,
        items: items,
        coupon: coupon,
      },
    })
  },

  AddPaymentInfo(items, value, coupon, payment_type) {
    this.pushToDataLayer({
      event: 'add_payment_info',
      ecommerce: {
        currency: 'BRL',
        value: value,
        coupon: coupon,
        payment_type: payment_type,
        items: items,
      },
    })
  },

  Purchase(items, value, coupon, transaction_id) {
    this.pushToDataLayer({
      event: 'purchase',
      ecommerce: {
        transaction_id: transaction_id,
        value: value,
        tax: 0,
        shipping: 0,
        currency: 'BRL',
        coupon: coupon,
        items: items,
      },
    })
  },
  Receipt() {
    this.pushToDataLayer({
      category: 'Inscrição',
      event: 'comprovante',
    })
  },
  Participants() {
    this.pushToDataLayer({
      category: 'Inscrição',
      event: 'participantes',
    })
  },
  UserId(id) {
    this.pushToDataLayer({
      category: 'Inscrição',
      event: 'userId',
      userId: id,
    })
  },
  Create() {
    this.pushToDataLayer({
      category: 'Inscrição',
      event: 'create',
    })
  },
  Login() {
    this.pushToDataLayer({
      category: 'Inscrição',
      event: 'login',
    })
  },
  Subscription() {
    this.pushToDataLayer({
      category: 'Inscrição',
      event: 'inscricoes',
    })
  },
  PageView() {
    this.pushToDataLayer({
      category: 'Inscrição',
      event: 'pageview',
    })
  },
}

export default GA4
