import { postLog } from '../logflare/postLog'

export async function transactionErrorHandler(response) {
  if (response.status >= 400 && response.status <= 499) {
    const text = await response.text()
    const data = JSON.parse(text)
    await postLog(data, 'Erro 400', '2561f024-3cd9-4d15-98f8-bbce1d896826')
    throw new Error(Object.values(data).join(' '))
  }
  if (response.status >= 500 && response.status <= 599) {
    const text = await response.text()
    const data = JSON.parse(text)
    await postLog(data, 'Erro 500', '2561f024-3cd9-4d15-98f8-bbce1d896826')
    throw new Error('Erro interno do servidor. Tente mais tarde.')
  }
  throw await response.json()
}
