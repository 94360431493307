const getExpirationTime = async () => {
  const expirationTime = 10000
  try {
    const res = await fetch('https://datetime.einscricao.workers.dev', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const data = await res.json()
    const expiration = data.now + expirationTime
    return expiration
  } catch (error) {
    const date = new Date(Date.now() + expirationTime)
    const expiration = date.getTime()
    return expiration
  }
}

const shiftChar = (char, shift) => {
  if (char >= 'A' && char <= 'Z') {
    const base = 'A'.charCodeAt(0)
    const charCode = char.charCodeAt(0)
    return String.fromCharCode(((((charCode - base + shift) % 26) + 26) % 26) + base)
  }
  return char
}

export const customerCode = async (code, eventId) => {
  const shift = eventId % 26
  const encryptedCode = code
    .split('')
    .map(char => shiftChar(char, shift))
    .join('')

  const expiration = await getExpirationTime()

  const combinedString = `${encryptedCode}|${eventId}|${expiration}`
  const encodedString = btoa(combinedString)

  return encodedString
}
