import React from 'react'
import { CategoryItem } from './CategoryItem'
import { view } from 'react-easy-state'

type ListProps = {
  list: {
    id: number
    qty: number
    label: string
    price: number
    limit: number
    onChange: any
    soldOut: any
    visible: boolean
    list_of_coupons: any
    name?: string
    min_tickets: number | null
    max_tickets: number | null
  }[]
  paymentMethods: {
    id: number
    payment_type: string
    amount_in_cents: number
    number_of_instalments: number
    begin_at: string
    end_at: string
    price: number
  }[]
}

const sample = {
  id: 1,
  qty: 0,
  label: 'Entrada',
  price: 99.9,
  limit: 1,
  visible: true,
  soldOut: false,
  onChange: undefined,
  list_of_coupons: {},
  min_tickets: null,
  max_tickets: null,
}

function CategoryList({ list = [sample], paymentMethods }: ListProps): any {
  return list.map((props, index) => (
    <CategoryItem key={index} paymentMethods={paymentMethods} {...props} />
  ))
}

export default view(CategoryList)
